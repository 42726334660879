/* home.css */
html, body {
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
}

main {
    flex: 1; /* Đảm bảo main chiếm toàn bộ không gian còn lại giữa Navbar và Footer */
}


.image-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 126px); 
    overflow: hidden;
    display: flex;
    flex-direction: row; 
    margin: 0; /* Loại bỏ margin */
    padding: 0; /* Loại bỏ padding nếu có */
    object-fit: cover;
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.image-container img.active {
    opacity: 1;

}

.about{
    width: 100%;
    height: 60px;
    background-color: #3A1212;
    padding-top: 10px;
}

.about a {
    color: #f1f1f1;
    text-decoration: none;
    margin: 0 10px;
}

.about a:hover {
    color: blue;
}

.about p {
    color: #f1f1f1;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 13px;
}

.btn {
    background: transparent; /* Chỉ hiển thị mũi tên */
    border: none; /* Xóa viền */
    color: black; /* Màu của mũi tên */
    font-size: 30px; /* Kích thước chữ */
    cursor: pointer; /* Thay đổi con trỏ khi di chuột */
    padding: 0; /* Bỏ padding */
  }
  
  .muiten-trai {
    left: 10px; /* Điều chỉnh khoảng cách từ bên trái */
  }
  
  .muiten-phai {
    right: 10px; /* Điều chỉnh khoảng cách từ bên phải */
  }
  
  .muiten-trai:hover,
  .muiten-phai:hover {
    color: gray;
  }
  /* CSS cho nút mũi tên trái */
  .swiper-button-next,
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.5); /* Nền mờ */
    color: white; /* Màu chữ trắng */
    border: none;
    border-radius: 50%; /* Nút tròn */
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.swiper-button-next {
    right: 10px;
}

.swiper-button-prev {
    left: 10px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Tăng độ mờ khi hover */
}

  

  @media (max-width: 768px) {
    .image-container {
        flex-direction: column; 
        height: auto; 
        overflow: visible; 
        padding: 0; 
        gap: 10px; 
        margin-top: 0px;
        /* padding-bottom: 90px; */
    }

    .swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
    display: none !important;
}

    /*fotter*/
    .about{
        width: 100%;
        height: 80px;
        background-color: #3A1212;
        padding-top: 16px;
    }

    .image-container img {
        height: auto; 
        width: 100%; 
        opacity: 1; 
        position: relative; 
        display: block; 
        margin: 0; /* Bỏ margin để ảnh sát lề */
    }

}

