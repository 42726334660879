
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
}


.main-about {
    max-width: 1200px; 
    margin: 50px auto;
    padding: 20px;
    /* background-color: #ffffff; */
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    margin-bottom: 75px !important;
}

.main-about p {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: justify;
    color:#000000;
}

.main-about p::first-letter {
    font-size: 1.5em;
    font-weight: bold;
    color: #007bff;
}

.main-about p span {
    font-style: italic;
    color: #555;
}

.main-about img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



