.about-foot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #3A1212;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #716c6c; 
    display: flex;
    flex-direction: column; /* Sắp xếp theo chiều dọc */
    align-items: center; /* Căn giữa theo chiều ngang */
    justify-content: center; /* Căn giữa theo chiều dọc */
}

.about-foot a {
    color: #f1f1f1;
    text-decoration: none;
    margin: 0 10px;
    font-size: small;
}

.about-foot a:hover {
    color: blue;
}

.about-foot p {
    color: #f1f1f1;
    margin: 0;
    padding: 0;
    line-height: 1.2;
}

@media (max-width: 768px) {
    /*fotter*/
    .about-foot{
        width: 100%;
        height: 80px;
        background-color: #3A1212;
        padding-top: 16px;
    }
}